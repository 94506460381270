import React from 'react'
import moment from 'moment'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMap from 'highcharts/modules/map'
import { useNavigate } from 'react-router-dom'

import { useGeoState } from '../GeoProvider'
import Spinner from '../Loader/Spinner'

import getMapOptions from './getMapOptions'
import dayjs from 'dayjs'
import CountingMethod from './../../utils/getCountingMethod'

const Map = ({
  toolTipFormatter,
  activeCbsasData,
  statesData,
  cbsaData,
  msaData,
  mapOptions,
  vulStateData,
  showStateLabels,
  enableTooltip,
  enableMapNavigation,
  enableMouseTracking,
  mapHeight = '70vh',
  mapWidth = '',
  dynamicMapTitle,
  dynamicMapLevelWeek,
  mapTitleDisable,
  mapType,
  enableButtons = false,
  stateJson,
  cbsaJson,
}) => {
  if (typeof Highcharts === 'object') {
    HighchartsMap(Highcharts)
  }

  const sundayDate = dayjs().day(0).format('YYYY-MM-DD')
  const actualsDate = dayjs().day(-14).format('YYYY-MM-DD')
  const actualsDateCovid = dayjs().day(-1).format('YYYY-MM-DD')
  const nowcastDate = dayjs().day(-7).format('YYYY-MM-DD')
  const { displayDate, regionSelected, displayWeek, dataSelected } =
    useGeoState()
  const history = useNavigate()
  let mapActiveCbsaData = null
  let mapStatesData = null
  let mapCbsaData = null
  let mapMsaData = null

  if (mapType !== undefined && mapType.toUpperCase() === 'CIACCURACY') {
    const statesDataForDisplayDate = Object.entries(statesData['ENSEMBLE'])
    mapStatesData = statesDataForDisplayDate.map((state) => {
      return {
        value: state[1].OVERALL[mapOptions.value],
        mape: state[1].OVERALL[mapOptions.mapeValue],
        mae: state[1].OVERALL[mapOptions.maeValue],
        rmse: state[1].OVERALL[mapOptions.rmseValue],
        mase: state[1].OVERALL[mapOptions.maseValue],
        state: state[0],
        ...state[1].OVERALL,
      }
    })

    if (regionSelected === 'msa' && cbsaData) {
      const cbsaDataForDisplayDate = Object.entries(cbsaData['ENSEMBLE'])
      mapCbsaData = cbsaDataForDisplayDate.map((metro) => {
        return {
          value: metro[1].OVERALL[mapOptions.value],
          mape: metro[1].OVERALL[mapOptions.mapeValue],
          mae: metro[1].OVERALL[mapOptions.maeValue],
          rmse: metro[1].OVERALL[mapOptions.rmseValue],
          mase: metro[1].OVERALL[mapOptions.maseValue],
          cbsa: metro[0],
          ...metro[1].OVERALL,
        }
      })

      mapActiveCbsaData = activeCbsasData.map((cbsa) => {
        return cbsa.toString()
      })
    }
  } else if (mapType !== undefined && mapType.toUpperCase() === 'COVIDACC') {
    const statesDataForDisplayDate = Object.entries(statesData['ENSEMBLE'])
    mapStatesData = statesDataForDisplayDate.map((state) => {
      return {
        value:
          dataSelected === 'revised_accuracy'
            ? state[1].OVERALL[mapOptions.value]
            : state[1].OVERALL[mapOptions.value],
        mape:
          dataSelected === 'revised_accuracy'
            ? state[1].OVERALL[mapOptions.mapeValue]
            : state[1].OVERALL[mapOptions.mapeValue],
        mae:
          dataSelected === 'revised_accuracy'
            ? state[1].OVERALL[mapOptions.maeValue]
            : state[1].OVERALL[mapOptions.maeValue],
        rmse:
          dataSelected === 'revised_accuracy'
            ? state[1].OVERALL[mapOptions.rmseValue]
            : state[1].OVERALL[mapOptions.rmseValue],
        mase:
          dataSelected === 'revised_accuracy'
            ? state[1].OVERALL[mapOptions.maseValue]
            : state[1].OVERALL[mapOptions.maseValue],
        state: state[0],
        ...state[1].OVERALL,
        
      }
    })
    
    if (regionSelected === 'msa' && cbsaData) {
      const cbsaDataForDisplayDate = Object.entries(cbsaData['ENSEMBLE'])
      
      mapCbsaData = cbsaDataForDisplayDate.map((metro) => {
        return {          
          value:
            dataSelected === 'revised_accuracy'
              ? metro[1].OVERALL[mapOptions.value]
              : metro[1].OVERALL[mapOptions.value],
          mape:
            dataSelected === 'revised_accuracy'
              ? metro[1].OVERALL[mapOptions.mapeValue]
              : metro[1].OVERALL[mapOptions.mapeValue],
          mae:
            dataSelected === 'revised_accuracy'
              ? metro[1].OVERALL[mapOptions.maeValue]
              : metro[1].OVERALL[mapOptions.maeValue],
          rmse:
            dataSelected === 'revised_accuracy'
              ? metro[1].OVERALL[mapOptions.rmseValue]
              : metro[1].OVERALL[mapOptions.rmseValue],
          mase:
            dataSelected === 'revised_accuracy'
              ? metro[1].OVERALL[mapOptions.maseValue]
              : metro[1].OVERALL[mapOptions.maseValue],
          cbsa: metro[0],
          ...metro[1].OVERALL,
       
        }
      })

      mapActiveCbsaData = activeCbsasData.map((cbsa) => {
        return cbsa.toString()
      })
    }
  } else if (mapType !== undefined && mapType.toUpperCase() === 'VUL') {
    const statesDataForDisplayDate = Object.entries(vulStateData.stateMapModel)
    //const statesDataForDisplayDate = Object.entries(statesData[displayDate])
    mapStatesData = statesDataForDisplayDate.map((state) => {
      return {
        value: state[1].projected_Pct,
        state: state[1].pat_Statecode.toUpperCase(),
        ...state[1],
      }
    })
  } else if (mapType !== undefined && mapType.toUpperCase() === 'COVERAGEMAP') {
    const stateDateData = Object.entries(statesData[sundayDate])
    mapStatesData = stateDateData.map((state) => {
      return {
        value: state[1].MR[mapOptions.value],
        state_per: state[1].MR[mapOptions.value],
        state_count: state[1].MR[mapOptions.stateCount],
        mr: state[1].MR[mapOptions.lobValue],
        mr_per: state[1].MR[mapOptions.lobPer],
        ei: state[1].EI[mapOptions.lobValue],
        ei_per: state[1].EI[mapOptions.lobPer],
        cs: state[1].CS[mapOptions.lobValue],
        cs_per: state[1].CS[mapOptions.lobPer],
        population: CountingMethod(state[1].MR[mapOptions.popCount], 'fixed'),
        state: state[0],
        ...state[1].MR,
      }
    })
  } else if (mapType !== undefined) {        
    const statesDataForDisplayDate = Object.entries(statesData[displayDate])
    mapStatesData = statesDataForDisplayDate.map((state) => {
      return {
        value: state[1][mapOptions.value],
        dataSelected: dataSelected,
        state: state[0],
        ...state[1],
      }
    })
    

    if (regionSelected === 'metro' || regionSelected === 'msa' && cbsaData) {
      const cbsaDataForDisplayDate = Object.entries(cbsaData[displayDate])
      mapCbsaData = cbsaDataForDisplayDate.map((cbsa) => {
        return {
          value: cbsa[1][mapOptions.value],
          dataSelected: dataSelected,
          cbsa: cbsa[0].toString(),
          ...cbsa[1],
        }
      })
      mapActiveCbsaData = activeCbsasData.map((cbsa) => {
        return cbsa.toString()
      })
    }
    if (regionSelected === 'msa' && msaData) {
      const cbsaDataForDisplayDate = Object.entries(msaData[displayDate])
      mapMsaData = cbsaDataForDisplayDate.map((cbsa) => {
        return {
          value: cbsa[1][mapOptions.value],
          dataSelected: dataSelected,
          cbsa: cbsa[0].toString(),
          ...cbsa[1],
        }
      })
      // mapActiveCbsaData = activeCbsasData.map((cbsa) => {
      //   return cbsa.toString()
      // })
    }
  }

  let title = mapOptions.mapTitle
  if (!(title === null || title === undefined)) {
    let regionString = ''
    switch (regionSelected) {
      case 'state':
        regionString = 'States'
        break
      case 'metro':
        regionString = 'States + Metro Areas'
        break
      case 'msa':
        regionString = 'States + CBSA'
        break
      case 'county':
        regionString = 'Counties'
        break
      default:
        regionString = ''
    }

    //added
    if (dynamicMapTitle === true && mapType === 'COVIDMAP') {
      if (displayDate <= actualsDateCovid) {
          title = title + ' - Actuals' 
      } else {
          title = title + ' - Forecast' 
      }
    } else if (dynamicMapTitle === true && mapType === 'FLUMAP') {
      if (displayDate === nowcastDate) {
        title = title + ' Forecast for ' + regionString
      } else if (displayDate < actualsDate) {
        // title = title + ' Actuals for ' + regionString
        title = 'Influenza Levels - Actual'
      } else {
        //title = title + ' Forecast for 2' + regionString
        title = 'Influenza Levels - Forecasted'
      }
    } else if (dynamicMapTitle === true && mapType === 'HeatMap') {
      title = title + ' Forecast for ' + regionString
    } else if (dynamicMapTitle === true && mapType === 'CIACCURACY') {
      title = 'Flucast Accuracy Heat Map'
    } else if (dynamicMapTitle === true && mapType === 'COVIDACC') {
      title = 'Covid Accuracy Heat Map'
    }
    else {
      title = title + ' for ' + regionString
    }

    if (dynamicMapLevelWeek === true) {
      if (displayDate === nowcastDate) {
        title =
          'Influenza Forecast for (' +
          moment(displayDate).format('MMMM D, YYYY') +
          '- Epiweek' +
          displayWeek +
          ')'
      } else if (displayDate < actualsDate) {
        title =
          'Influenza Actuals for (' +
          moment(displayDate).format('MMMM D, YYYY') +
          '- Epiweek' +
          displayWeek +
          ')'
      } else {
        title =
          'Influenza Forecast for (' +
          moment(displayDate).format('MMMM D, YYYY') +
          '- Epiweek' +
          displayWeek +
          ')'
      }
    }
    if (mapTitleDisable === true) {
      title = ''
    }
  }

  const graphOptions = getMapOptions(
    mapOptions.colorAxis,
    mapOptions.legend,
    toolTipFormatter,
    mapActiveCbsaData,
    mapCbsaData,
    mapStatesData,
    mapMsaData,
    history,
    title,
    mapOptions.mapSubTitle,
    showStateLabels,
    enableTooltip,
    enableMapNavigation,
    enableMouseTracking,
    mapType,
    enableButtons,
    stateJson,
    cbsaJson,
  )

  return mapOptions ? (
    <HighchartsReact
      containerProps={{
        style: {
          height: mapHeight,
          width: mapWidth,
          position: 'relative',
          display: 'flex',
        },
      }}
      constructorType="mapChart"
      highcharts={Highcharts}
      options={graphOptions}
      allowChartUpdate
      updateArgs={[true, true, false]}
    />
  ) : (
    <Spinner />
  )
}

export default Map
