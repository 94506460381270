import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import {components } from 'react-select'
import TrendingDown from '../../../assets/images/icon/trending_down.png'
import TrendingUp from '../../../assets/images/icon/trending_up.png'
import CompareArrows from '../../../assets/images/icon/compare_arrows.png'
import CloseIcon from '../../../assets/images/icon/close_compare.png'
import { Checkbox, FormField, Label } from '@better-ui/react'

import { useGeoFetch,useGeoState } from '../../../components/GeoProvider'
import styles from './HeaderTab.module.scss'
import Spinner from '../../../components/Loader/Spinner'
import ErrorMessage from '../../../components/Layout/ErrorMessage'

import { useAuthState } from '../../../components/AuthProvider'
import moment from 'moment'
import dayjs from 'dayjs'

import { startDateHome, endDate } from '../../Surveillance/dates'
import ComparisonChart from './ComparisonChart'
import ModelTabs from './ModelTabs'

function GetTabValueData(data){
  let HeaderDataObject=[]
  for(let i=0;i<data.length;i++)
  {
      let chartJson=JSON.parse(data[i].json_value)
      let maxDateInt=Math.max(...chartJson.map(member => Date.parse(member.week_ending)))
      let maxDateDate=(moment(maxDateInt).utc().format('YYYY-MM-DD'))
      chartJson.map((chartval)=>{
       
        if(chartval.week_ending===maxDateDate)
        {
         
          HeaderDataObject.push({disease_id:data[i].disease_id,
            disease:data[i].disease_name,
            trendValue:chartval.istrue,
            trendPercentage:chartval.change_actual_percentage})
        }
      })
    
  }

  return HeaderDataObject
}

const HeaderTab = ({seasonData,diseaseData}) => {
  
  const entity='surveillancenew'
  const { user } = useAuthState()
  const [isCompare, setIsCompare] = useState(false); 
  const [natsAll, setAllNATs] = useState()
  const [natsHeaderAll, nseNatsHeaderAll] = useState()
  const {diseaseSelectedSurv,setDiseaseSelectedSurv}=useGeoState('all')
  const {setIsChecked}=useGeoState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  let source='Claims Data'
  let updatedDate=dayjs().day(1).format('YYYY-MM-DD')
  const dataSeason = seasonData.map((season) => {
    return {
      value: season.data_id,
      label: season.data_value,
    }
  })

  const dataDisease = diseaseData.map((disease) => {
    return {
      value: disease.data_id,
      label: disease.display_value,
    }
  })


  const [selectedOptions, setSelectedOptions] = useState([dataSeason[0]])
  const [selectedOptionsDisease, setSelectedOptionsDisease] = useState([dataDisease[0]])
  const [selectSeasonComma, setSelectSeasonComma] = useState("2024-25")
  const [selectDiseaseComma, setSelectDiseaseComma] = useState("1")
  const [selectDiseaseValueComma, setSelectDiseaseValueComma] = useState("Flu")

  const { data: natsAllData, error: natAllDataError } = useGeoFetch(
    startDateHome,
    endDate,
    entity,
    'comparediseaseseason?geo_type=NAT&season='+selectSeasonComma+'&disease='+selectDiseaseComma
  )

  useEffect(() => {
    if (natsAllData !== undefined) {      
      if (natsAllData.response_code === 200) {        
        setAllNATs(natsAllData.response_data)
     } else {
       setErrorCode(natsAllData.response_code)
       setErrorMessage(natsAllData.response_message)
       setErrorSubject(natsAllData.response_subject)          
     }
    
    }
  }, [natsAllData])

  const { data: natsHeadereAllData, error: natHeaderAllDataError } = useGeoFetch(
    startDateHome,
    endDate,
    entity,
    'headerdata'
  )

  useEffect(() => {
    if (natsHeadereAllData !== undefined&& natsHeadereAllData!==null) {     
      if (natsHeadereAllData.response_code === 200) {        
        nseNatsHeaderAll(natsHeadereAllData.response_data)
     } else {
       setErrorCode(natsHeadereAllData.response_code)
       setErrorMessage(natsHeadereAllData.response_message)
       setErrorSubject(natsHeadereAllData.response_subject)          
     }
    
    }
  }, [natsHeadereAllData])


  const toggleCompare = () => { 
    setIsCompare(!isCompare); 
  }; 
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '4px',
      border: '1px solid #4B4D4F',
      boxSizing: 'border-box',
      cursor: 'pointer',
    }),
    option: (base, state) => ({
      ...base,
      color: '#1e2022',
      backgroundColor: 'white',
      padding: '.5rem 3rem .5rem .5rem',
      cursor: 'pointer',
      fontWeight: state.isSelected ? 'bold' : '',
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#002677',
        color: 'white',
        borderRadius: '100px',
        cursor: 'pointer',
        padding: '0px 0px 0px 5px',
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'white',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#002677',
      borderRadius: '100px',
      width: '32px',

      '&:hover': {
        backgroundColor: '#002677',
        color: 'white',
        borderRadius: '100px',
      },
    }),
  }

  function tabsChangeHandlerNew(e) {
    if ((e.length<=1) || (e.length <= 4 && selectedOptionsDisease.length<=1)) {
      setSelectedOptions(e)
     let valu=''
      e.map((dd)=>{
        if(valu==='')
        {
          valu+=dd.label
        }
        else
        {
          valu+=","+dd.label
        }
      })
      setSelectSeasonComma(valu)

    }
  }
  function tabsChangeHandlerNew1(e) {
    if ((e.length<=1) || (e.length <= 4 && selectedOptions.length<=1)) {
      setSelectedOptionsDisease(e)
      let valu=''
      let vale=''
      e.map((dd)=>{
        if(valu==='')
        {
          valu+=dd.value
          vale+=dd.label
        }
        else
        {
          valu+=","+dd.value
          vale+=","+dd.label
        }
      })
      setSelectDiseaseComma(valu)
      setSelectDiseaseValueComma(vale)

    }
  }
  function isHandleClick(option){
    setDiseaseSelectedSurv(option.disease.toLocaleLowerCase())
    setIsChecked(true)

  }
  if ( errorOccured || natHeaderAllDataError || natAllDataError) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }
//// Start here
const Option = (props) => {  
  const text =
  'Select up to 4 conditions to compare, 1 season at a time.'
  const text1 =
  'Select up to 4 seasons to compare, 1 condition at a time.'
  return (
    <>
     <div>
     
     {props.label === dataSeason[0].label? (
         <div>
             
       <div
         style={{ padding: '5px 5px', color: '#4B4D4F', fontSize: '14px',fontWeight:'700' }}
       >
       
        
       </div>
       <div style={{ padding: '5px 5px', color: '#4B4D4F', fontSize: '12px' }}> {text1}</div>
       </div>
       
     ) : (
       ''
     )}
      {props.label === dataDisease[0].label? (
         <div>
             
       <div
         style={{ padding: '5px 5px', color: '#4B4D4F', fontSize: '14px',fontWeight:'700' }}
       >
       
        
       </div>
       <div style={{ padding: '5px 5px', color: '#4B4D4F', fontSize: '12px' }}> {text}</div>
       </div>
       
     ) : (
       ''
     )}
     <components.Option {...props}>
       <FormField>
       <Checkbox checked={props.isSelected}> <Label  style={{paddingLeft:'8px'}}> {props.label}</Label></Checkbox>
      
       </FormField>
     </components.Option>
   </div>
   
    </>
   
  )

}
////End here 

  return (
          <div className={styles.headerDiv}>
           
           {
              natsHeaderAll!==undefined &&
              natsHeaderAll!==null?
               <div className={styles.subHead}>
            {
              GetTabValueData(natsHeaderAll).map((option) => {
              return(
                    <div  onClick={() => isHandleClick(option)
                    } 
                    className={diseaseSelectedSurv===option.disease.toLocaleLowerCase()?
                    styles.headerFrameWhite: styles.headerFrame}>
                    <img
                      src={option.trendValue===true?TrendingUp:TrendingDown}
                      alt="arrow-down"
                    ></img>
                    <span>{option.disease}</span>
                    </div>
                  )
            })}
             

              <div className={styles.iconFrame} onClick={toggleCompare}>
              <img
                src={isCompare?CloseIcon:CompareArrows}
                alt="arrow-down"
                style={{ height: '30px', width: '30px', marginBottom: '-3px' }}
              ></img>
              
              </div>
            
            </div>:<Spinner/>
            }
          <div className={isCompare?styles.compareGraphDiv:styles.compareGraphDivHide}>
          <div className={styles.flexDiv} >
          <div className={styles.titleDiv} >
            <span className={styles.captionFormat}>Compare Respiratory Conditions & Seasons</span>
          </div>
          <div className={styles.filterDiv}  >
          <div
            className={styles.selectDiv}
          >
             <span>Seasons</span>
            <Select
              isMulti
              options={dataSeason}
               onChange={tabsChangeHandlerNew}
              value={selectedOptions}
              placeholder={`Select Season(s)`}
              styles={colourStyles}
              components={{
                Option,
              }}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
            />
          </div>
          <div
            className={styles.selectDiv}
          >
             <span>Respiratory Conditions</span>
            <Select
              isMulti
              options={dataDisease}
               onChange={tabsChangeHandlerNew1}
              value={selectedOptionsDisease}
              placeholder={`Select Disease(s)`}
              styles={colourStyles}
              components={{
                Option,
              }}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
            />
          </div>
          </div>
          
        </div>
        {natsAll!==undefined? 
        <ModelTabs chartData={natsAll} 
        selectedSeasons={selectSeasonComma} 
        selectedDisease={selectDiseaseComma}
        selectDiseaseValueComma={selectDiseaseValueComma}/>
        :<Spinner/>}
 
          </div>
          </div>
  )
}

export default HeaderTab
