import dedupList from '../../utils/dedupeList'
import mapEvent from './mapEvent'
function getStateOptions(enableDataLabels, stateJson) {
  return {
    //borderColor: 'gray',
    borderWidth: 1,
    mapData: stateJson,
    name: 'US RSV Incidence',
    joinBy: ['hc-a2', 'state'],
    animation: false,
    cursor: 'pointer',
    allowPointSelect: true,

    states: {
      select: {
        //color: null,
        //borderColor: 'blue',
      },
      hover: {
        //borderColor: 'lightgray',
      },
    },
    dataLabels: {
      enabled: enableDataLabels,
      formatter: function () {
        return (
          '<div style="border:solid 1px;color:black;padding:0.5em;text-align:center;font-size: 12px;background: #FAE0DA;text-shadow: 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.3em white, 0 0 0.4em white;">' +
          this.point["hc-a2"]!==undefined?this.point["hc-a2"]:'' +
          '</div>'
        )
      },
    },
  }
}

function getCbsaOptions(activeCbsas, showCbsaLabels, getJsonData) {
  //usMsaAll.features = getJsonData
  let filteredFeats = []
  getJsonData.features.forEach((feat) => {
    activeCbsas.includes(feat.properties.geoid)
    if (activeCbsas.includes(feat.properties.geoid)) {
      filteredFeats.push(feat)
    }
  })

  getJsonData.features = filteredFeats

  return {
    borderColor: 'gray',
    borderWidth: 1,
    mapData: getJsonData,
    name: 'Infection rate',
    dataLabels: {
      enabled: showCbsaLabels,
      formatter: function () {
        return (
          '<div style="border:solid 1px;color:black;padding:0.5em;text-align:center;font-size: 12px;background: #FAE0DA;text-shadow: 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.3em white, 0 0 0.4em white;">' +
          this.point.geo_name +
          '</div>'
        )
        // return `<div style="font-size:14px;font-weight: 700;color:#000000; text-shadow: 0 0 3px #FFFFFF, 0 0 5px #FFFFFF;">
        //   ${this.point.geo_name}
        //   </div>`
      },
    },
    joinBy: ['geoid', 'state'],
    animation: false,
    cursor: 'pointer',
    allowPointSelect: true,
    states: {
      select: {
        color: null,
        borderColor: 'gray',
      },
      hover: {
        borderColor: 'gray',
      },
    },
  }
}
function getZip3Options(activeZip3, enableDataLabels, zip3Json,zoomLocation) {

  let filteredFeats = []
  zip3Json.features.forEach((feat) => {
    activeZip3.includes(feat.properties.geoid)
    if (activeZip3.includes(feat.properties.geoid)) {
      filteredFeats.push(feat)
    }
  })

  zip3Json.features = filteredFeats

  return {
    //borderColor: 'gray',
    borderWidth: 1,
    mapData: zip3Json,
    name: 'US Flu Incidence',
    joinBy: ['geoid', 'geo_id'],
    animation: false,
    cursor: 'pointer',
    allowPointSelect: true,
    enabled: enableDataLabels,
    states: {
      select: {
        //color: null,
        //borderColor: 'blue',
      },
      hover: {
        //borderColor: 'lightgray',
      },
    },
    dataLabels: {
      enabled: enableDataLabels,
      formatter: function () {
        var value = (parseInt( zoomLocation) === parseInt(this.point.geo_id)) ? this.point.geo_id:"";   
        return (
          '<div style="border:solid 1px;color:black;padding:0.5em;text-align:center;font-size: 12px;background: #FAE0DA;text-shadow: 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.2em white, 0 0 0.3em white, 0 0 0.4em white;">' +
          value +
          '</div>'
        )
      },
    },
  }
}


function getMapOptions(
  colorAxis,
  legend,
  toolTipFormatter,
  activeCbsasData,
  cbsaData,
  stateData,
  msaData,
  history,
  mapType,
  enableButtons,
  zoomLocation,
  mapDataType,
  JsonData,
  stateJson,
  dashboardType,
  titleText = null,
  subtitleText = null,
  showStateLabels = true,
  showCbsaLabels = false,
  enableTooltip = true,
  enableMapNavigation = true,
  enableMouseTracking = true,
  zip3Json,
  zip3DataFiltered,
  mapActiveZIP3Data,
  showZip3Labels = true,
  enableMouseZoom,
) {
  let cbsaOptions = {}
  let stateOptions = {}
  let zip3Options = {}
  if (cbsaData === null) {
    cbsaOptions.visible = false // = {}
  } else {
    cbsaOptions = getCbsaOptions(activeCbsasData, showCbsaLabels, JsonData)
    cbsaOptions.visible = true
    cbsaOptions.data = dedupList(cbsaData)
  }
  if (stateData === null) {
    stateOptions.visible = false // = {}
  } else {
    stateOptions = getStateOptions(showStateLabels, stateJson)
    stateOptions.visible = true
    stateOptions.data = dedupList(stateData)
  }
  if (zip3DataFiltered===null) {
    zip3Options.visible = false
  }
  else{   
    zip3Options = getZip3Options(mapActiveZIP3Data, showZip3Labels, zip3Json,zoomLocation)
    zip3Options.visible = true
    zip3Options.data = zip3DataFiltered
  }
  return {
    title: {
      text: titleText,
      style: {
        fontWeight: '700',
        fontsize: '8px',
      },
      // align:
      //   mapType.toUpperCase() == 'CIACCURACY' || 'COVIDACC' ? 'center' : 'left',
    },
    subtitle: {
      text: subtitleText,
    },
    chart: {
      backgroundColor:'none',
      displayErrors: false,
      events: {
        load: function () {
          let point = this.series[mapDataType].points.find(function (p) {
            return p.state === zoomLocation
          })

          setTimeout(function () {
            if (point !== null && point !== undefined) {
              point.state !== null && point.zoomTo()
            }
          }, 1000)
        },
      },
    },

    colorAxis: colorAxis,
    legend: legend,
    series: [stateOptions, cbsaOptions,zip3Options],

    mapNavigation: {
      enabled: enableMapNavigation,
      // enableDoubleClickZoomTo: true,
      enableMouseWheelZoom: enableMouseZoom,
      enableButtons: enableButtons,
      buttonOptions: {
        verticalAlign: 'bottom',
        align: 'right',
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#0C55B8',
          backgroundColor: '#FFFFFF',
         
        },
      },
    },
    tooltip: {
      enabled: enableTooltip,
      formatter: toolTipFormatter,
      borderRadius: 10,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      outside: true,
      style: {
        padding: 0,
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      map: {
        enableMouseTracking: enableMouseTracking,
        point: {
          events: dashboardType==="SURV"?{
            select: function () {
              return false
            },
            unselect: function () {},
          }:{
            select: function () {
              mapEvent(mapType, this.options, history, dashboardType)
              return false
            },
            unselect: function () {},
          },
        },
      },
    },
  }
}

export default getMapOptions