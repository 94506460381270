import styles from './GenderAgeDetails.module.scss'

const GraphCount = ({ data, chartName, isMale }) => {
  
  if(chartName==='genderAgechartSurv')
  {return (
    <div className={styles.valueFormat}>
      <span>
        {isMale === true
          ? data.male_count.toFixed(3)
          : data.female_count.toFixed(3)}
      </span>
    </div>
  )}
  else{return (
    <div className={styles.valueFormat}>
      <span>
        {isMale === true
          ? data.male_Count.toFixed(3)
          : data.female_Count.toFixed(3)}
      </span>
    </div>
  )}
  
}
const getGenderAgeDetails = ({ data,chartName,renderType }) => {
  if (data !== null && data !== undefined) {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <div className={styles.genderMale}>
            <span>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.102051"
                  width="16"
                  height="16"
                  rx="4"
                  fill="#15A796"
                />
              </svg>
            </span>
          </div>
          <div className={styles.textFormat} style={{ minWidth: '50px' }}>
            <span>Male</span>
          </div>

          {data.map((data_val) => {
            return <GraphCount data={data_val} chartName={chartName} isMale={true} />
          })}
        </div>
        <div style={{ display: 'flex' }}>
          <div className={styles.genderFemale}>
            <span>
              {renderType==='dashboard'?  <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.102051"
                  width="16"
                  height="16"
                  rx="4"
                  fill="#AEE2D5"
                />
              </svg>:<svg width="16" height="17" viewBox="0 0 16 17" fill="none">
                <rect y="0.102051" width="16" height="16" rx="4" fill="white" />
                <rect
                  y="0.102051"
                  width="16"
                  height="16"
                  rx="4"
                  fill="url(#pattern0)"
                />
                <defs>
                  <pattern
                    id="pattern0"
                    patternContentUnits="objectBoundingBox"
                    width="4.875"
                    height="6.625"
                  >
                    <use href="#image0_2028_19602" transform="scale(0.03125)" />
                  </pattern>
                  <image
                    id="image0_2028_19602"
                    width="156"
                    height="212"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJwAAADUCAYAAABps0pMAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQRSURBVHgB7dohblRRFIDh2zyPIOAaZMMKUHV0DaygKDBIUM+hWQQaxQoQJFgMBgQCAQoESVXbVDRt2k6nYv7MZL6rJpN8eTcnx7yXf+fz47fH48LZfbk/dl/sn/3+/ubj+PPh67jL4flFfjp8cDBf/OPfl59j7Ixx78mjcf/p3jj69Xf8//Z7LHt4fpG/snDFQ/nt9dcu3Kofym+vv3HhVvlQfnv9woVb10vzm+tvXbh1vDS/uX6phVu3S/Ob65deuHW6NL+5fnr9/NVsaHzlp3ef3s+Gxld+mk+PofGVnw4fHsyGxld+evZjbzY0vvJnb6mGxlf+/LOIofGFv/QdztD4VXs9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn3o9HJ96PRyfej0cn/oTv2J4NtBw2UMAAAAASUVORK5CYII="
                  />
                </defs>
              </svg>}
          
              
              
            </span>
          </div>
          <div className={styles.textFormat} style={{ minWidth: '50px' }}>
            <span>Female</span>
          </div>
          {data.map((data_val) => {
            return <GraphCount data={data_val} chartName={chartName} isMale={false}></GraphCount>
          })}
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}

export default getGenderAgeDetails