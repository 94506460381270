import React from 'react'
import { Link } from 'react-router-dom'
import { useAuthState } from '../AuthProvider'
import { useLocation, useNavigate } from 'react-router-dom'

const BackToHomeLink = () => {
  const { isAuthenticated } = useAuthState()

  return isAuthenticated ? (
    <Link to="/">Back to Home</Link>
  ) : (
    <>
      <Link to="/">Sign In</Link>
    </>
  )
}

export default BackToHomeLink
