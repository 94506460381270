export default function spreadOutObject(
  obj,
  targetOutputLength,
  loadedDayIndex,
  latestPositionString,
) {
  const originalObjLength = Object.keys(obj).length

  if (originalObjLength === 0) {
    return false
  }

  if (originalObjLength <= targetOutputLength) {
    return obj
  }

  const output = { 0: obj[0] }
  //const interval =5
   const interval =((originalObjLength / targetOutputLength).toFixed()-1)
  for (
    let index =0;// originalObjLength - 1;
    Object.keys(output).length <= targetOutputLength;
    index += interval
    // let index = originalObjLength - 1;
    // Object.keys(output).length <= targetOutputLength;
    // index -= interval
  ) {
    // eslint-disable-next-line prefer-destructuring
    output[Math.floor(index)] = obj[Math.floor(index)]
  }

   output[loadedDayIndex] = latestPositionString
  return output
}
