import React, { useState, useEffect } from 'react'

import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import Content from '../../components/Content'
import Layout from '../../components/Layout'
import SystemNotifications from './SystemNotifications'
import CovidMap from './DashboardMap'
import FluMap from './FluMap'
import HrrMap from './HRRMap'
import BottomGraphPanel from './BottomGraphPanel'
import RiskPredictorPanel from './RiskPredictorPanel'
import EmptyMap from '../../components/Map/emptyMap'
import styles from './Dashboard.module.scss'
import DashboardHeader from './DashboardHeader'
import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'
import GeoProvider from '../../components/GeoProvider'
import dataDisplayOptions from '../CovidPharma/dataDisplayOptions'
import { startDate, endDate, displayDate } from '../CovidPharma/dates'

const Dashboard = () => {
 
  const [errorOccured, seterrorOccured] = useState(false)
  
  const history = useNavigate()
  const [getStateJsonFile, setStateJsonFile] = useState();
  


  const [getfilterts, filtersObj] = useState({
    type: 'All',
    lob: 'All',
    season: '12',
    month: 'All',
  })
  const [getMemberVaccinated, setMemberVaccinated] = useState({
    MemberVaccinated: '0M',
  })

  const { user } = useAuthState()

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Dashboard',
    indicator_name: 'Dashboard',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }

  useEffect(() => {
    activityLog(logObject)
  }, [1])
  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log(data)
        setStateJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load usStatesAll ");
      });
  }, [1]);
  const [getJsonData, setJsonData] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/us_hrr_values.json`
    fetch(url)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // console.log(data)
        setJsonData(data);
      })
      .catch(function (err) {
        console.log(err, " error file load us_hrr_values ");
      });
  }, [1]);
  if (errorOccured) {
    return (
      <Layout>
        <Content>
         
        </Content>
      </Layout>
    )
  }


  return (
    <Layout>
      <Content>
        <SystemNotifications />
        <DashboardHeader />
        <div className={styles.base}>
          <div className={styles.fluPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div className={styles.mapHeaderDiv}>
                  <span className={styles.mapName}>Influenza Level</span>
                </div>
              </div>
              <>
              <div id="fluMap">
              <FluMap staticStateJson={getStateJsonFile} />
                
                </div>
              </>
              <div
                style={{ position: 'absolute', bottom: '0px' }}
                className={styles.sourceDiv}
              >
                <span className={styles.graphSource}>
                  Source : <span>Medical Claims Data</span>
                </span>

              </div>
            </div>
          </div>
          <div className={styles.covidPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div className={styles.mapHeaderDiv}>
                  <span className={styles.mapName}>
                    COVID Incidence{' '}
                  </span>
                </div>
              </div>
              <>
               
                <GeoProvider startDate={startDate}
                  endDate={endDate}
                  displayDate={displayDate}
                  mapOptions={dataDisplayOptions[0]['options'][0]}
                  indicatorName={dataDisplayOptions[0]['options'][0].indicatorName}
                >
                  <CovidMap/>
                </GeoProvider>
              </>
              <div
                style={{ position: 'absolute', bottom: '0px' }}
                className={styles.sourceDiv}
              >
                <span className={styles.graphSource}>
                  Source : <span>UDW data</span>
                </span>
              </div>
            </div>
          </div>

          <div className={styles.hrrPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div className={styles.mapHeaderDiv}>
                  <span>COVID-19 Weekly Hospitalizations</span>
                </div>
              </div>

              {getJsonData ? (
                <>                  
                    <HrrMap hrrdata={getJsonData} staticStateJson={getStateJsonFile} />                
                </>
              ) : (
                <EmptyMap stateJson={getStateJsonFile} mapHeight={'40vh'} />
              )}
              
              <div
                style={{ position: 'absolute', bottom: '0px' }}
                className={styles.sourceDiv}
              >
                <span className={styles.graphSource}>
                  Source : <span>Health and Human Services</span>
                </span>

              </div>
            </div>
          </div>
        </div>
        <RiskPredictorPanel />

        <BottomGraphPanel
          getMemberVaccinated={getMemberVaccinated}
          getfilterts={getfilterts}
        />


      </Content>
    </Layout>
  )
}

export default Dashboard
